import React from "react";

const Popover = ({ className, children, openPopover, style }) => {
  return (
    <>
      {openPopover && (
        <>
          <div className={`lna_popover_wrapper ${className}`} style={style}>
            {children}
          </div>
        </>
      )}
    </>
  );
};

export default Popover;

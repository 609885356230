import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { HiOutlineRefresh } from "react-icons/hi";
import { IoPerson } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import CustomLink from "../../components/Link";
import { Notification } from "../../components/ToastNotification";
import { loginValidate } from "../../components/Validation/vaildate";
import { useAddLoginMutation } from "../../Services/userApi";
import {
  getLocalStorage,
  removeLocalStorage,
  savedLocalStorage,
} from "../../utils/function/localStorage";
import AuthCardLayout from "./AuthCardLayout";

export let popupShow = false;
const Login = () => {
  const [errors, setErrors] = useState({}); // error catch
  const navigate = useNavigate();
  const [value, setValue] = useState({
    userId: "",
    password: "",
    otpCode: "",
  });
  const [captcha, setCaptcha] = useState({ x: 0, y: 0 });
  const [captchaRefresh, setCaptchaRefresh] = useState(false);
  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  // auth check
  useEffect(() => {
    if (getLocalStorage("self_life_care_token")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  // error
  useEffect(() => {
    setErrors(loginValidate(value));
  }, [value]);

  // add user
  const [addLogin, { error, data, isLoading }] = useAddLoginMutation();
  useEffect(() => {
    if (data?.message) {
      Notification(data?.message, "success");
      navigate("/dashboard");
      popupShow = true;
      savedLocalStorage("self_life_care_token", data?.token);
      removeLocalStorage("otp_timer");
    } else {
      Notification(error?.data?.message, "error");
      refresh();
    }
  }, [error, data, navigate]);

  if (JSON.parse(getLocalStorage("otp_timer"))) {
    setTimeout(() => {},
    parseInt(JSON.parse(getLocalStorage("otp_timer"))) * 1000);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(errors).length > 0) {
      Notification(errors?.userId || errors?.password, "error");
    } else {
      // console.log(captcha?.x, captcha?.y, parseInt(value?.otpCode));
      const logData = {
        ...value,
        userId: value.userId,
      };
      // console.log(logData);
      await addLogin(logData);
    }
  };
  useEffect(() => {
    setCaptcha({
      ...captcha,
      x: Math.floor(Math.random() * 10 + 1),
      y: Math.floor(Math.random() * 10 + 1),
    });
  }, [captchaRefresh]);

  const refresh = async () => {
    setCaptchaRefresh(!captchaRefresh);
  };
  const [showPassword, setShowPassword] = useState(false);
  const token = getLocalStorage("self_life_care_token");
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);
  return (
    <div className="lna__frontPage">
      <div className="lna_dashboard_login_page_wrapper">
        <AuthCardLayout className="lna_dashboard_login_card lna_all_card">
          <div className="login-body">
            <div className="half-width">
              <div className="right-content">
                <div className="lna_section_title">
                  <h2>Login</h2>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "black",
                  }}
                >
                  <p>Sign in to start your session</p>
                </div>
                {/* <SocialIconForCardHearder /> */}
                <div className="lna_dashboard_login_content">
                  <form onSubmit={handleSubmit}>
                    <div className="form_group" style={{ display: "inherit" }}>
                      <Input
                        // label="User ID"
                        type="text"
                        name="userId"
                        placeholder="Enter your user ID"
                        onChange={handleChange}
                        value={value.userId}
                        className="userid_input input_field"
                        inputGroupClass="right"
                      />
                      <span style={{ marginTop: "0px" }}>
                        <IoPerson color="#000" />
                      </span>
                    </div>
                    <div className="form_group" style={{ display: "inherit" }}>
                      <Input
                        // label="Password"
                        type={`${showPassword ? "text" : "password"}`}
                        name="password"
                        placeholder="Enter your password"
                        onChange={handleChange}
                        value={value.password}
                        className="password_input input_field"
                        inputGroupClass="right"
                      />
                      <span
                        style={{ marginTop: "0px" }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <AiFillEye color="#000" />
                        ) : (
                          <AiFillEyeInvisible color="#000" />
                        )}
                      </span>
                    </div>

                    <Button
                      type="submit"
                      className="submit_btn"
                      // disabled={OTPup}
                    >
                      {isLoading ? "Loading..." : "Login"}
                    </Button>
                    <div className="go_to_register">
                      <p>
                        <CustomLink
                          href="/register"
                          className="log_page_nav_link"
                        >
                          Not Registered? Join Now
                        </CustomLink>{" "}
                      </p>
                    </div>
                    <div className="go_to_register">
                      <p>
                        <CustomLink
                          href="/forgotPassword"
                          className="log_page_nav_link"
                        >
                          Forgot Password?
                        </CustomLink>{" "}
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </AuthCardLayout>
      </div>
    </div>
  );
};

export default Login;

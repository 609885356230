import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const settingApi = createApi({
  reducerPath: "settingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("self_life_care_token"));
      return headers;
    },
  }),
  tagTypes: ["setting", "meeting", "notification"], // automatic-data fetching
  endpoints: (builder) => ({
    // get popup image
    getPopupImage: builder.query({
      // SAST API
      query: () => "/api/v1/public/get_all_image",
      providesTags: ["setting"], // automatic-data fetching
    }),
    getPopupImageAdmin: builder.query({
      // SAST API
      query: () => "/api/v1/private/get_popup_img",
      providesTags: ["setting"], // automatic-data fetching
    }),
    // admin
    addPopupImage: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/change_popup_img",
        method: "POST",
        body,
      }),
      invalidatesTags: ["setting"],
    }),
    // for admin to post image in home page
    createAdminHomepageImage: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/create_image",
        method: "POST",
        body,
      }),
      invalidatesTags: ["setting"],
    }),
    // for admin to post image in home page
    CreateAdminHomepageVideo: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/create_vedio",
        method: "POST",
        body,
      }),
      invalidatesTags: ["setting"],
    }),
    addYoutubeVideo: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/change_video_link",
        method: "POST",
        body,
      }),
      invalidatesTags: ["setting"],
    }),
    addPdfLink: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/change_pdf_link",
        method: "POST",
        body,
      }),
      invalidatesTags: ["setting"],
    }),

    deleteGalleryImageByAdmin: builder.mutation({
      // SAST API
      query: (body) => ({
        url: "/api/v1/private/delete_image_byId",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["setting"],
    }),
    deleteGalleryVideoByAdmin: builder.mutation({
      // SAST API
      query: (body) => ({
        url: "/api/v1/private/delete_vedio_byId",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["setting"],
    }),

    getAdminAllPhotos: builder.query({
      query: () => "/api/v1/private/get_all_image",
      providesTags: ["setting"], // automatic-data fetching
    }),
    getAdminAllVideos: builder.query({
      query: () => "/api/v1/private/get_all_vedio",
      providesTags: ["setting"], // automatic-data fetching
    }),
    getYoutube: builder.query({
      query: () => "/api/v1/public/get_video_link",
      providesTags: ["setting"], // automatic-data fetching
    }),
    getPdfLink: builder.query({
      query: () => "/api/v1/public/get_pdf_link",
      providesTags: ["setting"], // automatic-data fetching
    }),
    getWebsiteAnalytics: builder.query({
      query: () => "/api/v1/public/website_analytics",
    }),
    // admin
    SlotControl: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/manageSlot",
        method: "POST",
        body,
      }),
      invalidatesTags: ["setting"],
    }),
    getSlotAdmin: builder.query({
      query: () => "/api/v1/private/getSlotControl",
    }),
    // admin
    createMeetingSchedule: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/createMeeting",
        method: "POST",
        body,
      }),
      invalidatesTags: ["meeting"],
    }),
    getAllNotification: builder.query({
      query: () => "/api/v1/private/getAllNotification",
    }),
    getAllContactFormHistory: builder.query({
      query: (query) =>
        `/api/v1/private/contact_us_data?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["notification"],
    }),
    deleteNotification: builder.mutation({
      query: (body) => ({
        url: `/api/v1/private/deleteNotification/${body.id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["notification"],
    }),
    deletePopUpImage: builder.mutation({
      query: (body) => ({
        url: `/api/v1/private/delete_image`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["setting"],
    }),
  }),
});

export const {
  useGetPopupImageQuery,
  useGetPopupImageAdminQuery,
  useAddPopupImageMutation,
  useAddYoutubeVideoMutation,
  useGetYoutubeQuery,
  useAddPdfLinkMutation,
  useGetPdfLinkQuery,
  useGetWebsiteAnalyticsQuery,
  useCreateAdminHomepageImageMutation,
  useCreateAdminHomepageVideoMutation,
  useGetAdminAllPhotosQuery,
  useGetAdminAllVideosQuery,
  useDeleteGalleryImageByAdminMutation,
  useDeleteGalleryVideoByAdminMutation,
  useSlotControlMutation,
  useGetSlotAdminQuery,
  useCreateMeetingScheduleMutation,
  useGetAllNotificationQuery,
  useDeleteNotificationMutation,
  useGetAllContactFormHistoryQuery,

  useDeletePopUpImageMutation,
} = settingApi;

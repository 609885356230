import React from "react";
import visionImg from "../../../../assets/frontpage/vision-img.png";

const Vision = () => {
  return (
    <div className="quality_container" style={{backgroundColor: '#CFDADE'}}>
      <div className="container">
        <div className="quality_inner">
          <div className="quality_left">
            <h2>Our Vision</h2>
            <p>
              At Self Life Care, we envision a world where everyone has the
              opportunity to turn their ideas into reality, regardless of their
              financial background.
            </p>

            <p>
              We believe that by leveraging the strengths of community-driven
              initiatives and crowdfunding, we can create a supportive ecosystem
              that fosters collaboration, innovation, and growth.
            </p>
          </div>
          <div className="quality_right">
            <img src={visionImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;

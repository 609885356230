import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const fundTransferApi = createApi({
  reducerPath: "fundTransferApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    mode: "cors",
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("self_life_care_token"));
      return headers;
    },
  }),
  tagTypes: ["adminFundTransfer", "userFundTransfer"], // automatic-data fetching
  endpoints: (builder) => ({}),
});

export const {} = fundTransferApi;

import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddUserMutation,
  useCheckPinStatusQuery,
  useGetCheckCallingNumberQuery,
  useGetValidateEmailQuery,
  useGetValidateMobileQuery,
  useGetValidateSponsorIdQuery,
} from "../../Services/userApi";
import register from "../../assets/register.png";
import Button from "../../components/Button";
import Input from "../../components/Input";
import CustomLink from "../../components/Link";
import Modal from "../../components/Modal";
import { Notification } from "../../components/ToastNotification";
import { Validate } from "../../components/Validation/vaildate";
import { useClickOutside } from "../../hooks/useClickOutside";
import { getLocalStorage } from "../../utils/function/localStorage";
import { takeScreenshot } from "../../utils/tools/screenShots";
import Footer from "../FrontPage/components/Footer";
import Header from "../FrontPage/components/Header";
import AuthCardLayout from "./AuthCardLayout";
import { FaCheck } from "react-icons/fa";
import RegisterPopUpModel from "../../components/Modal/RegisterPopUpModel";

const Register = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const sponsorid = parsed.sponsorid || "";
  const navigate = useNavigate();
  const [sponsorName, setSponsorName] = useState("");
  const [mobile, setMobile] = useState("");
  const [checked, setChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef(null);
  const [mobilError, setMobileError] = useState("");
  const [callingNumberError, setCallingNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  // useClickOutside(modalRef, () => setOpenModal(false));
  console.log({ openModal });
  const [user, setUser] = useState({
    fullName: "",
    email: "",
    sponsorId: sponsorid || "",
    sponsorName: "",
    password: "",
    confirmPassword: "",
    paymentMethod: "",
    a2zPay: "",
    usdt: "",
    bankName: "",
    holderName: "",
    accountNo: "",
    IFSCCode: "",
    pin: "",
    mobile: "",
    callingNumber: "",
    role: "user",
  });

  useEffect(() => {
    setUser((prevState) => ({
      ...prevState,
      mobile: mobile,
      sponsorName: sponsorName,
    }));
  }, [mobile, sponsorName]);
  const { data: validMobileData, error: validMobileError } =
    useGetValidateMobileQuery(user.mobile);
  const { data: validCallingData, error: validCallingError } =
    useGetCheckCallingNumberQuery(user.callingNumber);
  useEffect(() => {
    const validateMobile = async () => {
      if (!/^\d{10}$/.test(user.mobile)) {
        setMobileError("Invalid Mobile");
      } else {
        setMobileError("Valid Mobile");
      }
    };
    validateMobile();
  }, [user.mobile, validMobileData, validMobileError]);
  //calling Number
  useEffect(() => {
    const validateMobile = async () => {
      if (!/^\d{10}$/.test(user.callingNumber)) {
        setCallingNumberError("Invalid Calling Number");
      } else {
        setCallingNumberError("Valid Mobile");
      }
    };
    validateMobile();
  }, [user.callingNumber, validCallingData, validCallingError]);

  const { data: validEmailData, error: validEmailError } =
    useGetValidateEmailQuery(user.email);
  console.log({ validEmailError });
  console.log({ validEmailData });
  useEffect(() => {
    const validateEmail = async () => {
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(user.email)) {
        setEmailError("Invalid Email");
      } else {
        setEmailError("Valid Email");
      }
    };
    validateEmail();
  }, [user?.email, validEmailData, validEmailError]);

  const [errors, setErrors] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [sponsorError, setSponsorError] = useState("");
  const [checkPIN, setCheckPIN] = useState("");

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const { data: sponsorData, error: sponsorIdError } =
    useGetValidateSponsorIdQuery(user.sponsorId);
  const { data: pinData, error: pinError } = useCheckPinStatusQuery(user.pin);

  useEffect(() => {
    if (sponsorIdError?.data?.message) {
      setSponsorError(sponsorIdError?.data?.message);
      setSponsorName("");
    } else if (sponsorData?.name) {
      setSponsorError("Valid Sponsor Id");
      setSponsorName(sponsorData.name);
    }
  }, [sponsorIdError?.data?.message, sponsorData?.name]);
  // Pin
  useEffect(() => {
    if (pinError?.data?.message) {
      setCheckPIN(pinError.data.message);
    } else if (pinData) {
      setCheckPIN(pinData.message);
    }
  }, [pinError?.data?.message, pinData]);

  const [addUser, { data, error, isLoading }] = useAddUserMutation();

  useEffect(() => {
    if (data?.message) {
      Notification(data.message, "success");
      setOpenModal(true);
    } else if (error?.data?.message) {
      Notification(error.data.message, "error");
    }
  }, [error, data, navigate]);

  useEffect(() => {
    setFormErrors(Validate(user));
  }, [mobile, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({ user });
    console.log({ formErrors });
    if (Object.keys(formErrors).length > 0) {
      const errorMessages = Object.values(formErrors).join("\n");
      Notification(errorMessages, "error");
    } else {
      const dataUser = {
        sponsorId: user?.sponsorId,
        sponsorName: sponsorName,
        fullName: user?.fullName,
        email: user?.email,
        mobile: user?.mobile,
        password: user?.password,
        payAccount: user?.paymentMethod,
        bankName: user?.bankName,
        accountHolderName: user?.holderName,
        accountNo: user?.accountNo,
        IFSCCode: user?.IFSCCode,
        pin: user?.pin,
        a2zPay: user?.a2zPay,
        usdt: user?.usdt,
        callingNumber: user?.callingNumber,
      };
      // console.log({ dataUser });
      await addUser(dataUser);
    }
  };

  const token = getLocalStorage("self_life_care_token");

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  const handelCloseModal = () => {
    setOpenModal(false);
    navigate("/login");
  };

  const Screenshot = () => {
    takeScreenshot("targetDiv", "welcome-letter.png");
    navigate("/login");
  };

  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (
      (charCode >= 48 && charCode <= 57) || // Numbers 0-9
      !(
        (
          (charCode >= 65 && charCode <= 90) || // Uppercase letters A-Z
          (charCode >= 97 && charCode <= 122) || // Lowercase letters a-z
          charCode === 32
        ) // Space
      )
    ) {
      event.preventDefault();
    }
  };
  return (
    <>
      <div className="lna__frontPage">
        <div className="lna_dashboard_register_page_wrapper">
          <AuthCardLayout
            style={{ backgroundColor: "white" }}
            className="lna_dashboard_register_card"
          >
            <div className="register_header">
              <h5>Register a new User</h5>
            </div>
            <form onSubmit={handleSubmit} className="register-body">
              <div className="text-success">
                <h4>Sponsor Info</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Sponsor Id<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="sponsorId"
                    name="sponsorId"
                    placeholder="Enter Sponsor Id"
                    required
                    type="text"
                    value={user.sponsorId}
                    onChange={handleChange}
                  />
                  {user?.sponsorId && sponsorError && (
                    <p
                      style={{
                        textAlign: "start",
                        color: sponsorName ? "green" : "red",
                      }}
                    >
                      {sponsorError}
                    </p>
                  )}
                </div>
                <div className="col-md-6">
                  <label>
                    Sponsor Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="sponsorName"
                    name="sponsorName"
                    placeholder="Sponsor Name"
                    required
                    type="text"
                    value={sponsorName}
                    readOnly
                  />
                </div>
              </div>

              <div className="text-success">
                <h4>User Info</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    User Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="fullName"
                    name="fullName"
                    placeholder="Enter User Name"
                    required
                    type="text"
                    value={user.fullName}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                  />
                </div>
                <div className="col-md-6">
                  <label>
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email"
                    required
                    type="email"
                    value={user.email}
                    onChange={handleChange}
                  />
                  {user?.email && emailError && (
                    <p
                      style={{
                        textAlign: "start",
                        color: emailError === "Valid Email" ? "green" : "red",
                      }}
                    >
                      {emailError}
                    </p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Mobile Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="mobile"
                    name="mobile"
                    placeholder="Enter Mobile Number"
                    required
                    type="number"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                  {user?.mobile && mobilError && (
                    <p
                      style={{
                        textAlign: "start",
                        color: mobilError === "Valid Mobile" ? "green" : "red",
                      }}
                    >
                      {mobilError}
                    </p>
                  )}
                </div>
              </div>

              <div className="text-success">
                <h4>Account Info</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Calling-Whatsapp Number{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="callingNumber"
                    name="callingNumber"
                    placeholder="Enter Calling-Whatsapp Number"
                    required
                    type="number"
                    value={data?.callingNumber}
                    onChange={handleChange}
                  />
                  {user?.callingNumber && callingNumberError && (
                    <p
                      style={{
                        textAlign: "start",
                        color:
                          callingNumberError === "Valid Mobile"
                            ? "green"
                            : "red",
                      }}
                    >
                      {callingNumberError}
                    </p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label>PhonePay/ GooglePay/ Paytm Number</label>
                  <input
                    className="form-control"
                    id="paymentMethod"
                    name="paymentMethod"
                    placeholder="PhonePay/ GooglePay/ Paytm Number"
                    type="text"
                    value={user.paymentMethod}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>A2Z Pay</label>
                  <input
                    className="form-control"
                    id="a2zPay"
                    name="a2zPay"
                    placeholder=" Enter A2Z Pay Account"
                    type="text"
                    value={user.a2zPay}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>USDT(BEP20)</label>
                  <input
                    className="form-control"
                    id="usdt"
                    name="usdt"
                    placeholder="Enter USDT Account"
                    type="text"
                    value={user.usdt}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>
                    Bank Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="bankName"
                    name="bankName"
                    placeholder="Bank Name"
                    type="text"
                    value={user.bankName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Account Holder Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="holderName"
                    name="holderName"
                    placeholder="Holder Name"
                    type="text"
                    value={user.holderName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>
                    Account No <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="accountNo"
                    name="accountNo"
                    placeholder="Account No"
                    type="number"
                    value={user.accountNo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    IFSC Code<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="IFSCCode"
                    name="IFSCCode"
                    placeholder="IFSC Code"
                    required
                    type="text"
                    value={user.IFSCCode}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>
                    E-Pin No:<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="pin"
                    name="pin"
                    placeholder="Enter E-Pin"
                    required
                    type="text"
                    value={user.pin}
                    onChange={handleChange}
                  />
                  {user?.pin && checkPIN && (
                    <p
                      style={{
                        textAlign: "start",
                        color:
                          checkPIN === "Valid Pin (Price : ₹120)"
                            ? "green"
                            : "red",
                      }}
                    >
                      {checkPIN}
                    </p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>
                    Create Password <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Enter New Password"
                    required
                    type={checked ? "text" : "password"}
                    value={user.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>
                    Confirm Password <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Re-enter Password"
                    required
                    type={checked ? "text" : "password"}
                    value={user.confirmPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="form-check mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="terms"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <label className="form-check-label" htmlFor="terms">
                  Show Password
                </label>
              </div>

              <div className="already-member mt-3">
                <CustomLink to="/login">I already have a membership</CustomLink>
              </div>

              <div
                className="form-group mt-3"
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                  style={{
                    background: "#fd7e14",
                    color: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: "10px",
                  }}
                >
                  {isLoading ? "Loading..." : "Register"}
                </button>
              </div>
            </form>
          </AuthCardLayout>
        </div>
      </div>

      <RegisterPopUpModel
        openModal={openModal}
        setOpenModal={handelCloseModal}
        modalTitle="Welcome"
        modalRef={modalRef}
        className="contactUsForm__modal"
      >
        <div
          id="targetDiv"
          style={{ background: "blue", padding: "20px", color: "white" }}
          className="contactUsForm__modal"
        >
          <div className="form__group">
            <div
              className="input__group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label>User ID: </label>
              <p>{data?.data?.userId}</p>
            </div>
            <div
              className="input__group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label>Sponsor ID: </label>
              <p>{data?.data?.sponsorId}</p>
            </div>
          </div>
          <div className="form__group">
            <div
              className="input__group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label>Full Name: </label>
              <p>{data?.data?.fullName}</p>
            </div>
            <div
              className="input__group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label>Email: </label>
              <p>{data?.data?.email}</p>
            </div>
          </div>
          <div className="form__group">
            <div
              className="input__group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label>Mobile: </label> <p> {data?.data?.mobile}</p>
            </div>
          </div>
          <div className="form__group">
            <div
              className="input__group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label>Password: </label>
              <p>{user?.password}</p>
            </div>
          </div>
        </div>
        <div
          className="modal__footer__area"
          style={{
            textAlign: "center",
            justifyContent: "center",
            padding: "7px",
            cursor: "pointer",
          }}
        >
          <button
            type="button"
            onClick={Screenshot}
            style={{
              background: "#F15151",
              color: "white",
              padding: "10px",
              border: "none",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          >
            Screenshot
          </button>
        </div>
      </RegisterPopUpModel>
    </>
  );
};

export default Register;

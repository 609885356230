export const missions = [
  {
    id: 1,
    name: "Empower Individuals",
    desc: "We provide a platform where anyone can start a crowdfunding campaign, share their vision, and raise the necessary funds to bring their ideas to life.",
  },
  {
    id: 2,
    name: "Build Communities",
    desc: "Through our structured support network, we encourage the formation of strong, supportive groups that work together to achieve common goals.",
  },
  {
    id: 3,
    name: "Foster Innovation",
    desc: "By connecting like-minded individuals and providing the tools they need, we help cultivate an environment where new ideas can flourish.",
  },
  {
    id: 4,
    name: "Ensure Success",
    desc: "We offer comprehensive support, from campaign creation and management to marketing strategies and financial advice, ensuring our users have the best chance of success.",
  },
];

import React from "react";

const DoCard = ({ data }) => {
    return (
        <div className='do_card'>
            <p className="left_icon">{data?.icon}</p>
            <h3 className="title">{data?.name}</h3>
            <p className="description">{data?.desc}</p>
        </div>
    );
};

export default DoCard;
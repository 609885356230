import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import CustomLink from "../../components/Link";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { IoPerson } from "react-icons/io5";
import { forgotPasswordValidate } from "../../components/Validation/vaildate";
import {
  useAddForgotPassMutation,
  useSendOTPMutation,
} from "../../Services/userApi";
import { Notification } from "../../components/ToastNotification";
import AuthCardLayout from "./AuthCardLayout";
import { getLocalStorage } from "../../utils/function/localStorage";
import { useNavigate } from "react-router-dom";
const ForgotPassword = () => {
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [values, setValue] = useState({
    userId: "",
    password: "",
    confirmPassword: "",
    otpCode: "",
  });
  const handleChange = (e) => {
    setValue({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  // error
  useEffect(() => {
    setErrors(forgotPasswordValidate(values));
  }, [values]);

  // add user
  const [addForgot, { error, data, isLoading }] = useAddForgotPassMutation();
  const [sentOTP, { error: otpError, data: otpData, isLoading: otpLoading }] =
    useSendOTPMutation();
  useEffect(() => {
    if (data?.message) {
      Notification(data?.message, "success");
      setValue({});
      navigate("/login");
    } else {
      Notification(error?.data?.message, "error");
    }
  }, [error, data]);
  // For Otp
  useEffect(() => {
    if (otpData?.message) {
      Notification(otpData?.message, "success");
      setShowOTP(true);
    } else {
      Notification(otpError?.data?.message, "error");
    }
  }, [otpError, otpData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({ error });
    if (Object.keys(errors).length > 0) {
      Notification(errors, "error");
    } else {
      console.log({ values });
      await addForgot(values);
    }
  };
  // redirect
  const token = getLocalStorage("self_life_care_token");
  const navigate = useNavigate();
  //  const location = useLocation();
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  const handelLoginPage=()=>{
    navigate("/login");
  }
  const handelOTP = async (e) => {
    e.preventDefault();
    const obj = {
      userId: values?.userId,
    };
    await sentOTP(obj);
    // setShowOTP(true);
  };
  const reSentOTP = (e) => {
    e.preventDefault();
    setShowOTP(true);
  };
  console.log({ showOTP });
  return (
    <div className="lna__frontPage">
      <div className="lna_dashboard_login_page_wrapper">
        <AuthCardLayout className="lna_dashboard_login_card lna_all_card">
          <div className="login-body">
            <div className="half-width">
              <div className="right-content">
                <div className="lna_section_title">
                  <h2>Forgot Password</h2>
                </div>
                {/* <div
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    padding: "10px",
                    color: "black",
                  }}
                >
                  <p>Sign in to start your session</p>
                </div> */}
                {/* <SocialIconForCardHearder /> */}
                <div className="lna_dashboard_login_content">
                  <form>
                    <div className="form_group" style={{ display: "inherit" }}>
                      <Input
                        // label="User ID"
                        type="text"
                        name="userId"
                        placeholder="Enter your user ID"
                        onChange={handleChange}
                        value={values.userId}
                        className="userid_input input_field"
                        inputGroupClass="right"
                      />
                      <span style={{ marginTop: "0px" }}>
                        <IoPerson color="#000" />
                      </span>
                    </div>

                    {showOTP && (
                      <div>
                        <div
                          className="form_group"
                          style={{ display: "inherit" }}
                        >
                          <Input
                            // label="User ID"
                            type="number"
                            name="otpCode"
                            placeholder="Enter your OTP"
                            onChange={handleChange}
                            value={values.otpCode}
                            className="userid_input input_field"
                            inputGroupClass="right"
                          />
                          <Button
                            className="submit_btn"
                            onClick={handelOTP}
                            // disabled={OTPup}
                          >
                            {otpLoading ? "Loading..." : "Re-sent"}
                          </Button>
                        </div>
                        <div
                          className="form_group"
                          style={{ display: "inherit" }}
                        >
                          <Input
                            // label="Password"
                            type={`${showPassword ? "text" : "password"}`}
                            name="password"
                            placeholder="Enter new password"
                            onChange={handleChange}
                            value={values.password}
                            className="password_input input_field"
                            inputGroupClass="right"
                          />
                          <span
                            style={{ marginTop: "0px" }}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <AiFillEye color="#000" />
                            ) : (
                              <AiFillEyeInvisible color="#000" />
                            )}
                          </span>
                        </div>
                        <div
                          className="form_group"
                          style={{ display: "inherit" }}
                        >
                          <Input
                            // label="Password"
                            type={`${showPassword ? "text" : "password"}`}
                            name="confirmPassword"
                            placeholder="Confirm password"
                            onChange={handleChange}
                            value={values.confirmPassword}
                            className="password_input input_field"
                            inputGroupClass="right"
                          />
                          <span
                            style={{ marginTop: "0px" }}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <AiFillEye color="#000" />
                            ) : (
                              <AiFillEyeInvisible color="#000" />
                            )}
                          </span>
                        </div>
                      </div>
                    )}

                    {!showOTP && (
                      <Button
                        className="submit_btn"
                        onClick={handelOTP}
                        disabled={!values.userId}
                      >
                        {isLoading ? "Loading..." : "Sent OTP"}
                      </Button>
                    )}
                    {showOTP && (
                      <Button
                        type="submit"
                        className="submit_btn"
                        onClick={handleSubmit}
                        // disabled={OTPup}
                      >
                        {isLoading ? "Loading..." : "Submit"}
                      </Button>
                    )}

                    <div className="go_to_register" style={{color:'white'}}>
                      <Button  className="submit_btn" onClick={handelLoginPage}>

                  
                          Login
                 
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </AuthCardLayout>
      </div>
    </div>
  );
};

export default ForgotPassword;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const topupApi = createApi({
  reducerPath: "topupApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("self_life_care_token"));
      return headers;
    },
  }),
  tagTypes: [], // automatic-data fetching
  endpoints: (builder) => ({
    //admin
  }),
});

export const {} = topupApi;

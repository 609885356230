import React from "react";
const Footer = () => {
  return (
    <div className="ss-trade_front_footer_wrapper">
      <div className="container">
        <footer className="footer-container">
          <p style={{ color: "#fff !important" }}>
            Copyright © 2024-2040, Powered By{" "}
            <a href="https://selflifecare.com/">selflifecare.com</a>{" "}
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Footer;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const walletApi = createApi({
  reducerPath: "walletApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("self_life_care_token"));
      return headers;
    },
  }),
  tagTypes: ["userWallet", "autoTradeWithdraw", "pin"], // automatic-data fetching
  endpoints: (builder) => ({
    // user
    getAllWallet: builder.query({
      query: () => `/api/v1/secure/wallet/get_wallet`,
      providesTags: ["userWallet", "autoTradeWithdraw"], // automatic-data fetching
    }),
    depositHistory: builder.query({
      query: () => `/secure/api/deposite_history`,
      providesTags: ["userWallet"], // automatic-data fetching
    }),
    transferHistory: builder.query({
      query: () => `/secure/api/transfer_fund_history`,
      providesTags: ["userWallet"], // automatic-data fetching
    }),
    transferFund: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/transfer_fund",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userWallet"], // automatic-data fetching
    }),
    transferFundAdmin: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/transfer_fund",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userWallet"], // automatic-data fetching
    }),
    addDepositFund: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/wallet/deposit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userWallet"], // automatic-data fetching
    }),
    getDepositHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/wallet/deposit_history?page=${query.page}&limit=${query.limit}`,
      providesTags: ["userWallet"], // automatic-data fetching
    }),

    userTopupApiPackage: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/user_activation",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userWallet"], // automatic-data fetching
    }),

    getWithdrawHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/withdraw_history?page=${query.page}&limit=${query.limit}`,
      providesTags: ["userWallet"], // automatic-data fetching
    }),
    addWithdrawFunds: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/withdraw",
        method: "POST",
        body,
      }),
      invalidatesTags: ["userWallet"], // automatic-data fetching
    }),
    buyPinUser: builder.mutation({
      query: (body) => ({
        url: `/api/v1/secure/wallet/buy_pin?pinCount=${body?.pinCount}&pinTyp=${body?.pinType}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["userWallet"], // automatic-data fetching
    }),
    getSystemWallet: builder.query({
      query: (query) => `/api/v1/secure/wallet/getSystemWallet`,
      providesTags: ["userWallet"], // automatic-data fetching
    }),
  }),
});

export const {
  useGetWithdrawHistoryQuery,
  useAddWithdrawFundsMutation, // SAST API
  useUserTopupApiPackageMutation,
  useAddDepositFundMutation,
  useGetDepositHistoryQuery,
  useGetAllWalletQuery, // SAST API
  useDepositHistoryQuery,
  useTransferHistoryQuery,
  useTransferFundMutation,
  useTransferFundAdminMutation,
  useBuyPinUserMutation,
  useGetSystemWalletQuery,
} = walletApi;

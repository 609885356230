import {
  AiOutlineDashboard,
  AiOutlineToTop,
  AiOutlineWallet,
} from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";
import { FiSettings, FiUser } from "react-icons/fi";
import { GiTakeMyMoney } from "react-icons/gi";

import { MdOutlineSupportAgent } from "react-icons/md";
import { RiMoneyDollarCircleLine, RiTeamLine } from "react-icons/ri";

export const menus = [
  // ************************************* common dashboard menu ************************//
  {
    id: "6s65f6s6fs56f",
    menu: "dashboard",
    icon: <AiOutlineDashboard />,
    route: "/dashboard",
    permission: ["user"],
  },
  {
    id: "fs96sf9sf6s",
    menu: "dashboard",
    icon: <AiOutlineDashboard />,
    route: "/dashboard",
    permission: ["admin"],
  },
  // ************************************* admin dashboard menu ************************//
  {
    id: "7dfsegrr8srf",
    menu: "Member Data",
    icon: <FiUser />,
    permission: ["admin"],
    dropdown: [
      {
        id: "83agr89r4ifd",
        menu: "All Member List",
        route: "/dashboard/member/all-member",
        permission: ["admin"],
      },
      {
        id: "838dga9r4ifd",
        menu: "Auto Pool Member",
        route: "/dashboard/member/auto-pool-member",
        permission: ["admin"],
      },
      {
        id: "838dga9ifd",
        menu: "Blocked Member list",
        route: "/dashboard/member/blocked-member",
        permission: ["admin"],
      },
      {
        id: "1458s8e88wer",
        menu: "Team Statistics",
        route: "/dashboard/member/team-statistics",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "7ddldkdue8srf",
    menu: "Manage Help",
    icon: <GiTakeMyMoney />,
    permission: ["admin"],
    dropdown: [
      {
        id: "83bdldkd4ifd",
        menu: "All Transaction",
        route: "/dashboard/help/all-help-report",
        permission: ["admin"],
      },
      {
        id: "2942946wr6wr",
        menu: "Get All Help History",
        route: "/dashboard/help/get-all-help-history",
        permission: ["admin"],
      },
      {
        id: "83dldiejdyoifd",
        menu: "Manage Autopool Enter",
        route: "/dashboard/help/manage-autopool-entry",
        permission: ["admin"],
      },
      {
        id: "83dldldoeijdyoifd",
        menu: "Generate PIN By Admin",
        route: "/dashboard/help/generate-pin-by-admin",
        permission: ["admin"],
      },
      {
        id: "83dldldoeijdyoifd",
        menu: "PIN Transfer",
        route: "/dashboard/help/pin-transfer",
        permission: ["admin"],
      },
      {
        id: "83dldldkdjyeoifd",
        menu: "Special Level Eligibility",
        route: "/dashboard/help/special-level-eligibility",
        permission: ["admin"],
      },
      {
        id: "83dldkdjueheoifd",
        menu: " Help & Upgrade Id Eligibility",
        route: "/dashboard/help/help-upgrade-eligibility",
        permission: ["admin"],
      },
      {
        id: "83dldldoeijdyoifd",
        menu: "PIN Transfer History",
        route: "/dashboard/help/pin-transfer-history",
        permission: ["admin"],
      },
      {
        id: "8shbga9ifd",
        menu: "PIN Status",
        route: "/dashboard/help/pin-status",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "7dfsrs8srf",
    menu: "Deposit Report",
    icon: <GiTakeMyMoney />,
    permission: ["admin"],
    dropdown: [
      {
        id: "83bhi9r4ifd",
        menu: "All Transaction",
        route: "/dashboard/deposit/all-deposit",
        permission: ["admin"],
      },
      {
        id: "838dsoifd",
        menu: "Successful Report",
        route: "/dashboard/deposit/successful-deposit",
        permission: ["admin"],
      },
      {
        id: "8shbga9ifd",
        menu: "Rejected Report",
        route: "/dashboard/deposit/rejected-deposit",
        permission: ["admin"],
      },
    ],
  },
  {
    id: "ajjdrs8srf",
    menu: "withdraw Report",
    icon: <AiOutlineWallet />,
    permission: ["admin"],
    dropdown: [
      {
        id: "dajei9r4ifd",
        menu: "All Transaction",
        route: "/dashboard/Withdraw/all-withdraw",
        permission: ["admin"],
      },
      {
        id: "ajobeoifd",
        menu: "Successful Report",
        route: "/dashboard/Withdraw/successful-withdraw",
        permission: ["admin"],
      },
      {
        id: "aubia9ifd",
        menu: "Rejected Report",
        route: "/dashboard/Withdraw/rejected-withdraw",
        permission: ["admin"],
      },
    ],
  },

  {
    id: "nvsmiwsruwrwosf",
    menu: "Earning",
    icon: <GiTakeMyMoney />,
    permission: ["admin"],
    dropdown: [
      {
        id: "vmsmfowsrow5sf8s",
        menu: "Auto Pool Income",
        route: "/dashboard/earnings/autopool-income",
        permission: ["admin"],
      },
      {
        id: "vmsmfowldodkdw5sf8s",
        menu: "Re-Birth Auto Pool Income",
        route: "/dashboard/earnings/re-birth-autopool-income",
        permission: ["admin"],
      },
      // {
      //   id: "vmsmfodldidhw5sf8s",
      //   menu: "Matrix Income",
      //   route: "/dashboard/earnings/matrix-income",
      //   permission: ["admin"],
      // },
      // {
      //   id: "vmsmfodldldkduesf8s",
      //   menu: "Matrix Structure",
      //   route: "/dashboard/earnings/matrix-structure",
      //   permission: ["admin"],
      // },
      {
        id: "ldkdjddjdjd",
        menu: "Auto Pool Structure",
        route: "/dashboard/earnings/autopool-structure",
        permission: ["admin"],
      },
      {
        id: "ldjdhdhddjd",
        menu: "Table View Autopool",
        route: "/dashboard/earnings/table-view-autopool",
        permission: ["admin"],
      },
    ],
  },

  {
    id: "nf4au4awg43",
    menu: "Support",
    icon: <MdOutlineSupportAgent />,
    permission: ["admin"],
    dropdown: [
      {
        id: "30ht9fca",
        menu: "Create News",
        route: "/dashboard/create-update",
        permission: ["admin"],
      },
      {
        id: "30ht9fwa",
        menu: "Ticket Data",
        route: "/dashboard/ticket-data",
        permission: ["admin"],
      },
      {
        id: "kdjdeueyedh",
        menu: "POP Up",
        route: "/dashboard/pop-up",
        permission: ["admin"],
      },
    ],
  },

  {
    id: "sf4s8f7ser",
    menu: "Setting",
    icon: <FiSettings />,
    permission: ["admin"],
    dropdown: [
      {
        id: "f4s8fe8r8",
        menu: "Change Password",
        route: "/dashboard/change-password",
        permission: ["admin"],
      },
      {
        id: "f5sfsf8sf",
        menu: "Change Email",
        route: "/dashboard/change-email",
        permission: ["admin"],
      },

      {
        id: "f45dkdjdhf9sf9",
        menu: "Contact Us",
        route: "/dashboard/contact-from",
        permission: ["admin"],
      },
    ],
  },
  // ************************************* user dashboard menu ************************//
  {
    id: "7dfser8srf",
    menu: "Profile",
    icon: <FiUser />,
    permission: ["user"],
    dropdown: [
      {
        id: "8389r4ifd",
        menu: "View Profile",
        route: "/dashboard/profile/my-profile",
        permission: ["user"],
      },
      {
        id: "sf7s8f5s8er",
        menu: "Change Password",
        route: "/dashboard/profile/update-password",
        permission: ["user"],
      },
      {
        id: "sfs4f8se8",
        menu: "Change Email",
        route: "/dashboard/profile/update-email",
        permission: ["user"],
      },
      {
        id: "d7f8wer8s",
        menu: "Bank Wallet",
        route: "/dashboard/profile/edit-wallet",
        permission: ["user"],
      },
      {
        id: "d7f8dldkdjhd8s",
        menu: "PIN History",
        route: "/dashboard/profile/pin-history",
        permission: ["user"],
      },
    ],
  },
  {
    id: "f7df8sfd7f",
    menu: "Wallet",
    icon: <AiOutlineWallet />,
    permission: ["user"],
    dropdown: [
      {
        id: "7854s8dfs5d",
        menu: "My Wallet",
        route: "/dashboard/wallet/my-wallet",
        permission: ["user"],
      },
      {
        id: "7854s8dfs5d",
        menu: "Buy PIN",
        route: "/dashboard/wallet/buy-pin",
        permission: ["user"],
      },
      {
        id: "7854s8dfdkdue",
        menu: "PIN Transfer",
        route: "/dashboard/wallet/pin-transfer",
        permission: ["user"],
      },
      {
        id: "7854s8dfdkdue",
        menu: "PIN Transfer History",
        route: "/dashboard/wallet/pin-transfer-history",
        permission: ["user"],
      },
      {
        id: "7854s8ddkiee",
        menu: "PIN Receive History",
        route: "/dashboard/wallet/pin-receive-history",
        permission: ["user"],
      },
      {
        id: "8s8dfdepser",
        menu: "Deposit Fund",
        icon: <RiMoneyDollarCircleLine />,
        route: "/dashboard/wallet/deposit-fund",
        permission: ["user"],
      },
      {
        id: "fd7d8s48574",
        menu: "Deposit History",
        route: "/dashboard/wallet/deposit-history",
        permission: ["user"],
      },
    ],
  },
  {
    id: "df79er8suf",
    menu: "My Team",
    icon: <RiTeamLine />,
    permission: ["user"],
    dropdown: [
      {
        id: "r3er8re8sdf7dn",
        menu: "Direct Team",
        route: "/dashboard/team/direct-team",
        permission: ["user"],
      },
      {
        id: "fs7erw55s",
        menu: "My Teams",
        route: "/dashboard/team/level-team",
        permission: ["user"],
      },
      {
        id: "fs7erw55s",
        menu: "My Level Teams",
        route: "/dashboard/team/my-level-team",
        permission: ["user"],
      },
    ],
  },
  {
    id: "df79loeijeuf",
    menu: "Help",
    icon: <RiTeamLine />,
    permission: ["user"],
    dropdown: [
      {
        id: "r3er8re8ldkdjh7dn",
        menu: "Give Help",
        route: "/dashboard/help/give-help",
        permission: ["user"],
      },
      {
        id: "r3er8re8ldldkdj7dn",
        menu: "Give Help History",
        route: "/dashboard/help/give-help-history",
        permission: ["user"],
      },
      {
        id: "r3er8reldkdjldkdj7dn",
        menu: "Get Help History",
        route: "/dashboard/help/get-help-history",
        permission: ["user"],
      },
      {
        id: "r3er8reldkdjlkdiejdn",
        menu: "Get Help Data",
        route: "/dashboard/help/get-help-data",
        permission: ["user"],
      },
    ],
  },

  {
    id: "fsfsfusf8se4r",
    menu: "My Earning",
    icon: <GiTakeMyMoney />,
    permission: ["user"],
    dropdown: [
      {
        id: "12s8bsnfusf",
        menu: "Matrix income",
        route: "/dashboard/earnings/matrix-income",
        permission: ["user"],
      },
      {
        id: "12s8bsnfusf",
        menu: "Auto Pool Income",
        route: "/dashboard/earnings/autopool-income",
        permission: ["user"],
      },
      {
        id: "12s8bdkdiehejf",
        menu: "Re-Birth Auto Pool Income",
        route: "/dashboard/earnings/re-birth-autopool-income",
        permission: ["user"],
      },

      {
        id: "12s8bsnfusf",
        menu: "Enter Auto Pool",
        route: "/dashboard/earnings/enter-auto-pool",
        permission: ["user"],
      },
    ],
  },

  {
    id: "bv2sre2ws7sf",
    menu: "withdraw",
    icon: <BiTransfer />,
    permission: ["user"],
    dropdown: [
      {
        id: "vv4mk4ufi4sf",
        menu: "withdraw Funds",
        route: "/dashboard/withdraw/withdraw-fund",
        permission: ["user"],
      },
      {
        id: "vn2sff47s7f7d",
        menu: "Withdraw History",
        route: "/dashboard/withdraw/withdraw-history",
        permission: ["user"],
      },
    ],
  },
  {
    id: "vn3es6uru3sf7",
    menu: "support",
    icon: <MdOutlineSupportAgent />,
    permission: ["user"],
    dropdown: [
      {
        id: "sfs4f4s8e8r",
        menu: "Updates",
        route: "/dashboard/support/updates",
        permission: ["user"],
      },
      {
        id: "vmj4dfs4f8sf",
        menu: "Support Ticket",
        route: "/dashboard/support/support-ticket",
        permission: ["user"],
      },
      {
        id: "vmj4de4ru4sf7s7",
        menu: "Ticket History",
        route: "/dashboard/support/ticket-history",
        permission: ["user"],
      },
      {
        id: "f7s8f8fd",
        menu: "Contact Us",
        route: "/dashboard/support/contact-us",
        permission: ["user"],
      },
    ],
  },
];

import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../containers/AuthPages/Login";
import Register from "../containers/AuthPages/Register";
// import Register2 from "../containers/AuthPages/Register2";
import ForgotPassword from "../containers/AuthPages/ForgotPassword";
import Dashboard from "./dashboard";
import FrontPage from "./frontPage";
import ResetPassword from "../containers/AuthPages/ResetPassword";
import TermsConditions from "../containers/AuthPages/TermsConditions";
import VerifyUser from "../containers/AuthPages/VerifyUser";

const Routers = () => {
  return (
    <>
      {/* <BrowserRouter> */}
      <Routes>
        <Route path="/*" index element={<FrontPage />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="/register" element={<Register2 />} /> */}
        <Route path="/login/:token" element={<VerifyUser />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:token" element={<ResetPassword />} />
        <Route path="/termsconditions" element={<TermsConditions />} />
      </Routes>
      {/* </BrowserRouter> */}
    </>
  );
};

export default Routers;

import React from "react";
import DoCard from "../../components/Card/Docard";
import { ImStatsBars } from "react-icons/im";
import { GiHealthIncrease, GiGears,  } from "react-icons/gi";
import { MdOutlineGroups2 } from "react-icons/md";
import { BsFillBagCheckFill, BsShare } from "react-icons/bs";

const OurCoreServices = ({ refset }) => {
  const srvs = [
    {
      id: 1,
      name: "Unique Hybrid Model",
      icon: <ImStatsBars />,
      desc: "Our innovative approach combines the reach and scalability of structured support networks with the community-driven nature of crowdfunding, providing a robust platform for fundraising.",
    },
    {
      id: 2,
      name: "Comprehensive Support",
      icon: <GiGears />,
      desc: "We offer extensive resources and guidance to help you at every step of your journey, from initial planning to successful execution.",
    },
    {
      id: 3,
      name: "Community Focused",
      icon:<MdOutlineGroups2 />,
      desc: "We believe in the power of community and collaboration. Our platform is designed to foster strong connections and mutual support among users.",
    },
    {
      id: 4,
      name: "Proven Track Record",
      icon: <BsFillBagCheckFill />,
      desc: "Our team consists of experienced professionals in both community support and crowdfunding, ensuring that you receive the best advice and support possible.",
    },
  ];
  return (
    <div ref={refset} className="wedo_container">
      <div className="container">
        <div className="wedo_inner">
          <div className="wedo_header">
            <h2>Why Choose Us?</h2>
          </div>
          <div className="wedo_cards">
            {srvs?.map((d) => (
              <DoCard key={d?.id} data={d} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCoreServices;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const withdrawApi = createApi({
  reducerPath: "withdrawApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("self_life_care_token"));
      return headers;
    },
  }),
  tagTypes: ["adminWithdraw", "userWithdraw", "autoTradeWithdraw"], // automatic-data fetching
  endpoints: (builder) => ({
    // admin
    allWithdrawHistory: builder.query({
      query: (query) =>
        `/api/v1/private/show_all_withdraw?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminWithdraw"], // automatic-data fetching
    }),
    allAutoTradeWithdrawHistory: builder.query({
      query: () => "/api/v1/private/auto_trade_withdraw_requests",
      providesTags: ["autoTradeWithdraw"], // automatic-data fetching
    }),
    editAutoTradeWithdrawStatus: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/change_auto_trade_withdraw_status",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["autoTradeWithdraw"],
    }),
    editWithdrawStatus: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/update_withdraw_status",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["adminWithdraw"],
    }),
    completedWithdrawHistory: builder.query({
      query: (query) =>
        `/api/v1/private/get_success_withdraw?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminWithdraw"], // automatic-data fetching
    }),
    canceledWithdrawHistory: builder.query({
      query: (query) =>
        `/api/v1/private/get_rejected_withdraw?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminWithdraw"], // automatic-data fetching
    }),
    getAdminAllBalance: builder.query({
      query: () => "/payment/api/get_admin_balance",
      providesTags: ["adminWithdraw"], // automatic-data fetching
    }),
    updateALlWithdrawStatus: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/update_withdraw_all_status",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["adminWithdraw"],
    }),
    sendTokenAdmin: builder.mutation({
      query: (body) => ({
        url: "/payment/api/send_token",
        method: "POST",
        body,
      }),
      invalidatesTags: ["adminWithdraw"],
    }),
    updateALlWithdrawStatusReject: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/update_withdraw_all_status_reject",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["adminWithdraw"],
    }),
  }),
});

export const {
  useAllWithdrawHistoryQuery,
  useCompletedWithdrawHistoryQuery,
  useCanceledWithdrawHistoryQuery,
  useEditWithdrawStatusMutation,
  useAllAutoTradeWithdrawHistoryQuery,
  useEditAutoTradeWithdrawStatusMutation,
  useUpdateALlWithdrawStatusMutation,
  useUpdateALlWithdrawStatusRejectMutation,
  useSendTokenAdminMutation,
  useGetAdminAllBalanceQuery,
} = withdrawApi;

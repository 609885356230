import React from "react";
import herobg from "../../../../assets/hero-img.png";

const HeroHome = ({refset}) => {
  return (
    <div ref={refset} className="sst_home-banner">
      <div className="container sst_hero-container">
        <div className="sst_herro-left">
          <div className="sst_hero-subtitle">
            <h4>Empower Dreams, Foster Community, Achieve Together</h4>
          </div>
          <div className="sst_hero-title">
            <h1>Your Path to Success SELF LIFE CARE</h1>
          </div>
          <div className="sst_hero-text">
            <p>
            Empowering individuals through community-driven crowdfunding and support, turning ideas into reality with comprehensive resources and guidance.
            </p>
          </div>
        </div>
        <div className="sst_herro-right">
          <img src={herobg} />
        </div>
      </div>
    </div>
  );
};

export default HeroHome;

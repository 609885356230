import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const depositeApi = createApi({
  reducerPath: "depositeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("self_life_care_token"));
      return headers;
    },
  }),
  // ?pageNo=${query?.pageNo}&pagePerShow=${query?.pagePerShow}
  tagTypes: [
    "adminDeposite",
    "userDeposit",
    "pin",
    "auto-pool",
    "help",
    "pin-transfer",
  ], // automatic-data fetching
  endpoints: (builder) => ({
    // admin
    allDepositeHistory: builder.query({
      query: (query) =>
        `/api/v1/private/get_all_deposits?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminDeposite"], // automatic-data fetching
    }),
    getAllHelpHistoryByAdmin: builder.query({
      query: (query) =>
        `/api/v1/private/get_all_help_history_admin?page=${query.page}&limit=${query.limit}&giveHelpId=${query.giveHelpId}&takeHelpId=${query.takeHelpId}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["help"], // automatic-data fetching
    }),
    updateGetHelpStatusByAdmin: builder.mutation({
      query: (body) => ({
        url: `/api/v1/private/updateMatrixStatusByAdmin`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["help"],
    }),
    getAllHelpTransactionHistoryByAdmin: builder.query({
      query: (query) =>
        `/api/v1/private/getAllHelpTransactionByAdmin?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminDeposite"], // automatic-data fetching
    }),
    editDepositStatus: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/update_deposit_status",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["adminDeposite"],
    }),
    completedDepositeHistory: builder.query({
      query: (query) =>
        `/api/v1/private/get_success_deposits?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminDeposite"], // automatic-data fetching
    }),
    canceledDepositeHistory: builder.query({
      query: (query) =>
        `/api/v1/private/get_rejected_deposits?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminDeposite"], // automatic-data fetching
    }),
    getAllLife24DepositHistory: builder.query({
      query: (query) =>
        `/api/v1/private/getAllLife24DepositHistory?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminDeposite"], // automatic-data fetching
    }),

    getAllPinHistory: builder.query({
      query: (query) =>
        `/api/v1/private/get_pin_history?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["pin"], // automatic-data fetching
    }),
    enterAutoPoolAdmin: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/enter_to_auto_pool",
        method: "POST",
        body,
      }),
      invalidatesTags: ["auto-pool"],
    }),
    generatePINByAdmin: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/create_pin_by_admin",
        method: "POST",
        body,
      }),
      invalidatesTags: ["pin"],
    }),

    pinTransferAdmin: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/PinTransfer",
        method: "POST",
        body,
      }),
      invalidatesTags: ["pin-transfer"],
    }),

    getAllPinTransferHistory: builder.query({
      query: (query) =>
        `/api/v1/private/getAllPinTransferHistory?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["pin-transfer"], // automatic-data fetching
    }),
  }),
});

export const {
  useAllDepositeHistoryQuery,
  useCompletedDepositeHistoryQuery,
  useCanceledDepositeHistoryQuery,
  useEditDepositStatusMutation,
  useGetAllLife24DepositHistoryQuery,
  useGetAllPinHistoryQuery,
  useEnterAutoPoolAdminMutation,
  useGeneratePINByAdminMutation,
  useGetAllHelpTransactionHistoryByAdminQuery,
  useGetAllHelpHistoryByAdminQuery,
  useUpdateGetHelpStatusByAdminMutation,
  usePinTransferAdminMutation,
  useGetAllPinTransferHistoryQuery,
} = depositeApi;

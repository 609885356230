import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const helpApi = createApi({
  reducerPath: "helpApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("self_life_care_token"));
      return headers;
    },
  }),
  tagTypes: ["help", "pin-transfer"],
  endpoints: (builder) => ({
    getGetHelpHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/get_help_history?page=${query?.page}&limit=${query?.limit}`,
      providesTags: ["help"],
    }),
    getMyGetHelpData: builder.query({
      query: (query) =>
        `/api/v1/secure/get_my_give_help?page=${query?.page}&limit=${query?.limit}`,
      providesTags: ["help"],
    }),
    getGiveHelpHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/give_help_history?page=${query?.page}&limit=${query?.limit}`,
      providesTags: ["help"],
    }),
    getHelpItems: builder.query({
      query: (query) =>
        `/api/v1/secure/get_help_items?page=${query?.page}&limit=${query?.limit}`,
      providesTags: ["help"],
    }),
    getMatrixIncomeHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/get_matrix_income_history?page=${query?.page}&limit=${query?.limit}`,
      providesTags: ["help"],
    }),
    giveHelp: builder.mutation({
      query: (body) => ({
        url: `/api/v1/secure/give_help`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["help"],
    }),
    updateGetHelpStatus: builder.mutation({
      query: (body) => ({
        url: `/api/v1/secure/update_matrix_status`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["help"],
    }),
    pinTransfer: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/pin_transfer",
        method: "POST",
        body,
      }),
      invalidatesTags: ["pin-transfer"],
    }),
    getPinTransferHistoryUser: builder.query({
      query: (query) =>
        `/api/v1/secure/get_pin_transfer?page=${query?.page}&limit=${query?.limit}`,
      providesTags: ["pin-transfer"],
    }),
    getPinReceiverHistoryUser: builder.query({
      query: (query) =>
        `/api/v1/secure/get_pin_receiver?page=${query?.page}&limit=${query?.limit}`,
      providesTags: ["pin-transfer"],
    }),
    specialLevelEligibility: builder.mutation({
      query: (body) => ({
        url: `/api/v1/private/special_level_eligibility`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["help"],
    }),
    helpAndUpgradeEligibility: builder.mutation({
      query: (body) => ({
        url: `/api/v1/private/help_upgrade_eligibility`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["help"],
    }),
  }),
});

export const {
  useGetMatrixIncomeHistoryQuery,
  useGetGetHelpHistoryQuery,
  useGetGiveHelpHistoryQuery,
  useGetHelpItemsQuery,
  useGiveHelpMutation,
  useUpdateGetHelpStatusMutation,
  usePinTransferMutation,
  useGetPinTransferHistoryUserQuery,
  useGetPinReceiverHistoryUserQuery,
  useGetMyGetHelpDataQuery,
  useSpecialLevelEligibilityMutation,
  useHelpAndUpgradeEligibilityMutation,
} = helpApi;

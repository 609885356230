import React from "react";

import Page404 from "../containers/Page404NotFound/Page404";

const UserDashboard = React.lazy(() =>
  import("../containers/UserPages/HomePage/index")
);

// user profile
const MyProfile = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.MyProfile")
);
const UpDateProfile = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.UpdateProfile")
);
const UpDatePassword = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.UpdatePassword")
);
const UpDateEmail = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.UpdateEmail")
);
const EditWallet = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.UpdateWallet")
);
const PINHistory = React.lazy(() =>
  import("../containers/UserPages/UserProfilePage/User.PINHistory")
);

// wallet section
const wallet = React.lazy(() =>
  import("../containers/UserPages/Wallet/Wallet")
);
const DepositFund = React.lazy(() =>
  import("../containers/UserPages/Wallet/DepositFund")
);
const DepositHistory = React.lazy(() =>
  import("../containers/UserPages/Wallet/DepositFundHistory")
);
const BuyPIN = React.lazy(() =>
  import("../containers/UserPages/Wallet/BuyPIN")
);
const UserPINTransfer = React.lazy(() =>
  import("../containers/UserPages/PinTransfer/PINTransfer.js")
);
const UserPINTransferHistory = React.lazy(() =>
  import("../containers/UserPages/PinTransfer/PINTransferHistory.js")
);
const UserPINReceiverHistory = React.lazy(() =>
  import("../containers/UserPages/PinTransfer/PINReceiverHistory.js")
);

// Team section
const DirectTeam = React.lazy(() =>
  import("../containers/UserPages/MyTeamPage/Team.DirectTeamPage")
);
const LevelTeam = React.lazy(() =>
  import("../containers/UserPages/MyTeamPage/Team.LevelTeam")
);
const MyLevelTeam = React.lazy(() =>
  import("../containers/UserPages/MyTeamPage/Team.MYTeam.js")
);

// help section
const GiveHelp = React.lazy(() =>
  import("../containers/UserPages/Help/GiveHelp")
);
const GiveHelpHistory = React.lazy(() =>
  import("../containers/UserPages/Help/GiveHelpHistory")
);
const GetHelpHistory = React.lazy(() =>
  import("../containers/UserPages/Help/GetHelpHistory")
);
const GetHelpData = React.lazy(() =>
  import("../containers/UserPages/Help/MyGetHelpData.js")
);

// earning section

const AutoPoolIncome = React.lazy(() =>
  import("../containers/UserPages/Earnings/Earning.AutoPoolIncome")
);
const ReBirthAutoPoolIncome = React.lazy(() =>
  import("../containers/UserPages/Earnings/Earning.ReBirthAutoPoolIncome")
);
const EnterPoolIncome = React.lazy(() =>
  import("../containers/UserPages/Earnings/EnterAutoPool")
);
const MatrixIncome = React.lazy(() =>
  import("../containers/UserPages/Earnings/MatrixIncome")
);

// withdraw section
const WithdrawFund = React.lazy(() =>
  import("../containers/UserPages/WithdrawPage/Withdraw.WithdrawFundsPage")
);
const WithdrawHistory = React.lazy(() =>
  import("../containers/UserPages/WithdrawPage/Withdraw.ReportStatusPage")
);

// Support section
const updates = React.lazy(() =>
  import("../containers/UserPages/SupportPage/Support.UpdateNews")
);
const supportTicket = React.lazy(() =>
  import("../containers/UserPages/SupportPage/Support.SupportTicketPage")
);
const TicketHistory = React.lazy(() =>
  import("../containers/UserPages/SupportPage/Support.TicketHistoryPage")
);
const ContactUs = React.lazy(() =>
  import("../containers/UserPages/SupportPage/Support.ContactPage")
);

/* ***************************** //admin// ************************** */
const AdminDashboard = React.lazy(() =>
  import("../containers/AdminPages/AdminHome/AdminHome")
);

// member section
const AllMember = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Admin.AllMember")
);
const AutoPoolMember = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Admin.AutoPoolMember")
);
const BlockedMember = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Admin.BlockedMember")
);

const AdminTeamStatistics = React.lazy(() =>
  import("../containers/AdminPages/AdminMemberPage/Admin.TeamStatistics")
);

// help section admin
const AdminAllHelpReport = React.lazy(() =>
  import("../containers/AdminPages/Admin.Help/Admin.AllTransaction")
);
const GetHelpHistoryAdmin = React.lazy(() =>
  import("../containers/AdminPages/Admin.Help/GetHelpHistoryAdmin")
);
const AdminManageAutoPoolEnters = React.lazy(() =>
  import("../containers/AdminPages/Admin.Help/ManageAutoPoolEntry")
);
const AdminGeneratePINByAdmin = React.lazy(() =>
  import("../containers/AdminPages/Admin.Help/GeneratePINByAdmin")
);
const AdminPINStatus = React.lazy(() =>
  import("../containers/AdminPages/Admin.Help/Admin.GetHelpHistory")
);
const AdminPINTransfer = React.lazy(() =>
  import("../containers/AdminPages/Admin.PinTransfer/PINTransfer.js")
);
const SpecialLevelEligibility = React.lazy(() =>
  import(
    "../containers/AdminPages/Admin.PinTransfer/SpecialLevelEligibility.js"
  )
);
const HelpUpgradeEligibility = React.lazy(() =>
  import(
    "../containers/AdminPages/Admin.PinTransfer/Help&UpgradeEligibility.js"
  )
);
const AdminPINTransferHistory = React.lazy(() =>
  import("../containers/AdminPages/Admin.PinTransfer/PINTransferHistory.js")
);

// deposit section
const AllDeposit = React.lazy(() =>
  import("../containers/AdminPages/AdminDepositReport/Admin.AllTransaction")
);
const SuccessfulDeposit = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminDepositReport/Admin.SuccessfulTransaction"
  )
);
const RejectedDeposit = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminDepositReport/Admin.RejectedTransaction"
  )
);

// withdraw section
const AllWithdraw = React.lazy(() =>
  import("../containers/AdminPages/AdminWithdrawReport/Admin.AllWithdraw")
);
const SuccessfulWithdraw = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminWithdrawReport/Admin.SuccessfulWithdraw"
  )
);
const RejectedWithdraw = React.lazy(() =>
  import("../containers/AdminPages/AdminWithdrawReport/Admin.RejectedWithdraw")
);

// ******************** admin earnings ***************

const AdminAutoPoolIncome = React.lazy(() =>
  import("../containers/AdminPages/AdminEarningTrack/Admin.AutoPoolIncome")
);
const AdminReBirthAutoPoolIncome = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminEarningTrack/Admin.ReBirthAutoPoolIncome"
  )
);
const AdminTableViewAutoPoolIncome = React.lazy(() =>
  import("../containers/AdminPages/AdminEarningTrack/Admin.TableViewAutopool")
);
const AdminMatrixIncome = React.lazy(() =>
  import("../containers/AdminPages/AdminEarningTrack/Admin.MatrixIncome")
);
const AdminMatrixStructure = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminEarningTrack/Admin.MatrixIncomeStructure"
  )
);
const AdminPoolStructure = React.lazy(() =>
  import(
    "../containers/AdminPages/AdminEarningTrack/Admin.AutoPoolIncomeStructure"
  )
);
const AdminTableViewAutoPool = React.lazy(() =>
  import("../containers/AdminPages/AdminEarningTrack/Admin.TableViewAutopool")
);

// support section
const AdminCreateUpdate = React.lazy(() =>
  import("../containers/AdminPages/Admin.Support/CreateUpdate")
);
const AdminCreateUs = React.lazy(() =>
  import("../containers/AdminPages/Admin.Support/Admin.ContactUs")
);
const AdminTicketData = React.lazy(() =>
  import("../containers/AdminPages/Admin.Support/Admin.TicketData")
);
const AdminPOPUp = React.lazy(() =>
  import("../containers/AdminPages/Admin.Support/PopupImage")
);

const CreateNews = React.lazy(() =>
  import("../containers/AdminPages/Admin.Support/CreateNews")
);

// settings section
const AdminChangePassword = React.lazy(() =>
  import("../containers/AdminPages/Admin.Setting/Admin.ChangePassword")
);

const AdminChangeEmail = React.lazy(() =>
  import("../containers/AdminPages/Admin.Account/Admin.ChangeEmail")
);
// const AdminPopUpImage = React.lazy(() =>
//   import("../containers/AdminPages/Admin.Setting/PopupImage")
// );
const AdminPDFController = React.lazy(() =>
  import("../containers/AdminPages/Admin.Setting/Admin.PDFController")
);

const AdminContactForm = React.lazy(() =>
  import("../containers/AdminPages/Admin.Setting/Admin.ContactUs")
);
const AdminGallery = React.lazy(() =>
  import("../containers/AdminPages/Admin.Setting/Admin.Gallery")
);
const ManagePhotos = React.lazy(() =>
  import("../containers/AdminPages/Admin.Setting/Admin.ManagePhotos")
);
const ManageVideos = React.lazy(() =>
  import("../containers/AdminPages/Admin.Setting/Admin.ManageVideo")
);

export const routers = [
  // { path: '/user/logout', name: 'Logout', permission: ['user','admin'], component: Logout },
  /****************************user routes *****************************************/
  {
    path: "*",
    exact: true,
    name: "Error",
    permission: ["user", "admin"],
    component: Page404,
  },
  // Dashboard
  {
    path: "/",
    exact: true,
    name: "Dashboard",
    permission: ["user"],
    component: UserDashboard,
  },

  //###### SETTINGS CATEGORY#########################################
  // SETTINGS=>Profile Section
  {
    path: "/profile",
    name: "Welcome",
    permission: ["user"],
    component: MyProfile,
    exact: true,
  },
  {
    path: "/profile/my-profile",
    name: "My Profile",
    permission: ["user"],
    component: MyProfile,
  },
  {
    path: "/profile/edit-profile",
    name: "Update Profile",
    permission: ["user"],
    component: UpDateProfile,
  },
  {
    path: "/profile/update-email",
    name: "Update Email",
    permission: ["user"],
    component: UpDateEmail,
  },
  {
    path: "/profile/update-password",
    name: "Update Email",
    permission: ["user"],
    component: UpDatePassword,
  },

  {
    path: "/profile/edit-wallet",
    name: "Update Email",
    permission: ["user"],
    component: EditWallet,
  },
  {
    path: "/profile/pin-history",
    name: "Update Email",
    permission: ["user"],
    component: PINHistory,
  },

  // wallet section
  {
    path: "/wallet/my-wallet",
    name: "Update Email",
    permission: ["user"],
    component: wallet,
  },
  {
    path: "/wallet/deposit-fund",
    name: "Update Email",
    permission: ["user"],
    component: DepositFund,
  },
  {
    path: "/wallet/deposit-history",
    name: "Update Email",
    permission: ["user"],
    component: DepositHistory,
  },
  {
    path: "/wallet/buy-pin",
    name: "Buy PIN",
    permission: ["user"],
    component: BuyPIN,
  },
  {
    path: "/wallet/pin-transfer",
    name: "PIN Transfer",
    permission: ["user"],
    component: UserPINTransfer,
  },
  {
    path: "/wallet/pin-transfer-history",
    name: "PIN Transfer History",
    permission: ["user"],
    component: UserPINTransferHistory,
  },
  {
    path: "/wallet/pin-receive-history",
    name: "PIN Receiver History",
    permission: ["user"],
    component: UserPINReceiverHistory,
  },

  // Team section
  {
    path: "/team",
    name: "direct team",
    permission: ["user"],
    component: DirectTeam,
  },
  {
    path: "/team/direct-team",
    name: "direct team",
    permission: ["user"],
    component: DirectTeam,
  },
  {
    path: "/team/level-team",
    name: "level team",
    permission: ["user"],
    component: LevelTeam,
  },
  {
    path: "/team/my-level-team",
    name: "My level team",
    permission: ["user"],
    component: MyLevelTeam,
  },
  // help section
  {
    path: "/help/give-help",
    name: "give Help",
    permission: ["user"],
    component: GiveHelp,
  },
  {
    path: "/help/give-help-history",
    name: "give Help History",
    permission: ["user"],
    component: GiveHelpHistory,
  },
  {
    path: "/help/get-help-history",
    name: "get Help History",
    permission: ["user"],
    component: GetHelpHistory,
  },
  {
    path: "/help/get-help-data",
    name: "get Help Data",
    permission: ["user"],
    component: GetHelpData,
  },

  // earning section

  {
    path: "/earnings/autopool-income",
    name: "Auto Pool Income",
    permission: ["user"],
    component: AutoPoolIncome,
  },
  {
    path: "/earnings/re-birth-autopool-income",
    name: "Auto Pool Income",
    permission: ["user"],
    component: ReBirthAutoPoolIncome,
  },
  {
    path: "/earnings/enter-auto-pool",
    name: "Auto Pool Income",
    permission: ["user"],
    component: EnterPoolIncome,
  },
  {
    path: "/earnings/matrix-income",
    name: "Auto Pool Income",
    permission: ["user"],
    component: MatrixIncome,
  },

  // withdraw section
  {
    path: "/withdraw/withdraw-fund",
    name: "withdraw funds",
    permission: ["user"],
    component: WithdrawFund,
  },
  {
    path: "/withdraw/withdraw-history",
    name: "withdraw history",
    permission: ["user"],
    component: WithdrawHistory,
  },

  // Settings =>Support
  {
    path: "/support/updates",
    name: "updates",
    permission: ["user"],
    component: updates,
    exact: true,
  },
  {
    path: "/support/support-ticket",
    name: "support ticket",
    permission: ["user"],
    component: supportTicket,
    exact: true,
  },
  {
    path: "/support/ticket-history",
    name: "ticket history",
    permission: ["user"],
    component: TicketHistory,
    exact: true,
  },
  {
    path: "/support/contact-us",
    name: "Contact Us",
    permission: ["user"],
    component: ContactUs,
    exact: true,
  },

  /*******************************************admin routes *********************************************/
  {
    path: "/",
    exact: true,
    name: "Dashboard",
    permission: ["admin"],
    component: AdminDashboard,
  },

  // member section
  {
    path: "/member/team-statistics",
    exact: true,
    name: "Team Statistics",
    permission: ["admin"],
    component: AdminTeamStatistics,
  },
  {
    path: "/member/all-member",
    exact: true,
    name: "All Member",
    permission: ["admin"],
    component: AllMember,
  },
  {
    path: "/member/auto-pool-member",
    exact: true,
    name: "Auto Pool Member",
    permission: ["admin"],
    component: AutoPoolMember,
  },
  {
    path: "/member/blocked-member",
    exact: true,
    name: "Blocked Member",
    permission: ["admin"],
    component: BlockedMember,
  },
  //help Section
  {
    path: "/help/all-help-report",
    exact: true,
    name: "AllTransaction",
    permission: ["admin"],
    component: AdminAllHelpReport,
  },
  {
    path: "/help/get-all-help-history",
    exact: true,
    name: "AllTransaction",
    permission: ["admin"],
    component: GetHelpHistoryAdmin,
  },
  {
    path: "/help/manage-autopool-entry",
    exact: true,
    name: "AllTransaction",
    permission: ["admin"],
    component: AdminManageAutoPoolEnters,
  },
  {
    path: "/help/generate-pin-by-admin",
    exact: true,
    name: "AllTransaction",
    permission: ["admin"],
    component: AdminGeneratePINByAdmin,
  },
  {
    path: "/help/pin-status",
    exact: true,
    name: "Pin Status",
    permission: ["admin"],
    component: AdminPINStatus,
  },
  {
    path: "/help/pin-transfer",
    exact: true,
    name: "Pin Transfer",
    permission: ["admin"],
    component: AdminPINTransfer,
  },
  {
    path: "/help/special-level-eligibility",
    exact: true,
    name: "Specia lLevel Eligibility",
    permission: ["admin"],
    component: SpecialLevelEligibility,
  },
  {
    path: "/help/help-upgrade-eligibility",
    exact: true,
    name: "Help & Upgrade Eligibility",
    permission: ["admin"],
    component: HelpUpgradeEligibility,
  },
  {
    path: "/help/pin-transfer-history",
    exact: true,
    name: "Pin Transfer History",
    permission: ["admin"],
    component: AdminPINTransferHistory,
  },

  // deposit section
  {
    path: "/deposit/all-deposit",
    exact: true,
    name: "All Deposit",
    permission: ["admin"],
    component: AllDeposit,
  },
  {
    path: "/deposit/successful-deposit",
    exact: true,
    name: "Successful Deposit",
    permission: ["admin"],
    component: SuccessfulDeposit,
  },
  {
    path: "/deposit/rejected-deposit",
    exact: true,
    name: "Rejected Deposit",
    permission: ["admin"],
    component: RejectedDeposit,
  },

  // withdraw section
  {
    path: "/Withdraw/all-withdraw",
    exact: true,
    name: "All Withdraw",
    permission: ["admin"],
    component: AllWithdraw,
  },
  {
    path: "/Withdraw/successful-withdraw",
    exact: true,
    name: "Successful Withdraw",
    permission: ["admin"],
    component: SuccessfulWithdraw,
  },
  {
    path: "/Withdraw/rejected-withdraw",
    exact: true,
    name: "Rejected Withdraw",
    permission: ["admin"],
    component: RejectedWithdraw,
  },

  // ********************** earings *************

  {
    path: "/earnings/autopool-income",
    name: "Auto Pool Income",
    permission: ["admin"],
    component: AdminAutoPoolIncome,
  },
  {
    path: "/earnings/re-birth-autopool-income",
    name: "Re-Birth Auto Pool Income",
    permission: ["admin"],
    component: AdminReBirthAutoPoolIncome,
  },
  {
    path: "/earnings/table-view-autopool",
    name: "Table View Auto Pool Income",
    permission: ["admin"],
    component: AdminTableViewAutoPoolIncome,
  },
  {
    path: "/earnings/autopool-structure",
    name: "Auto Pool Income",
    permission: ["admin"],
    component: AdminPoolStructure,
  },
  {
    path: "/earnings/autopool-structure",
    name: "Table View Auto Pool",
    permission: ["admin"],
    component: AdminTableViewAutoPool,
  },
  {
    path: "/earnings/matrix-structure",
    name: "Auto Pool Income",
    permission: ["admin"],
    component: AdminMatrixStructure,
  },
  {
    path: "/earnings/matrix-income",
    name: "Auto Pool Income",
    permission: ["admin"],
    component: AdminMatrixIncome,
  },

  // support section
  {
    path: "/create-update",
    name: "Create Update",
    permission: ["admin"],
    component: AdminCreateUpdate,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    permission: ["admin"],
    component: AdminCreateUs,
  },
  {
    path: "/ticket-data",
    name: "Ticket Data",
    permission: ["admin"],
    component: AdminTicketData,
  },
  {
    path: "/pop-up",
    name: "POP Up",
    permission: ["admin"],
    component: AdminPOPUp,
  },

  {
    path: "/create-news",
    name: "Create News",
    permission: ["admin"],
    component: CreateNews,
  },

  // setting section
  {
    path: "/change-password",
    name: "Change Password",
    permission: ["admin"],
    component: AdminChangePassword,
  },
  {
    path: "/change-email",
    name: "Change Email",
    permission: ["admin"],
    component: AdminChangeEmail,
  },
  {
    path: "/pdf-controller",
    name: "PDF Controller",
    permission: ["admin"],
    component: AdminPDFController,
  },

  {
    path: `/contact-from`,
    name: "contact from",
    permission: ["admin"],
    component: AdminContactForm,
  },
  {
    path: "/gallery",
    name: "Gallery",
    permission: ["admin"],
    component: AdminGallery,
  },
  {
    path: "/gallery/manage-photos",
    name: "Manage Photos",
    permission: ["admin"],
    component: ManagePhotos,
  },
  {
    path: "/gallery/manage-videos",
    name: "Manage Videos",
    permission: ["admin"],
    component: ManageVideos,
  },
];

import React from "react";
import HeroHome from "../HeroHome";
import OurCoreServices from "./OurCoreServices";
import Mission from "./Mission";
import About from "../About";
import Vision from "./Vision";
const Home = ({refs}) => {

  return (
    <>
      <HeroHome refset={refs?.homeref} />
      <Mission />
      <Vision/>
      <OurCoreServices refset={refs?.servicesref} />
      <About refset={refs?.aboutref} />
    </>
  );
};

export default Home;

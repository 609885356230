import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const earningApi = createApi({
  reducerPath: "earningApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("authorization", getLocalStorage("self_life_care_token"));
      return headers;
    },
  }),
  tagTypes: ["adminEarning", "user", "boosterController"], // automatic-data fetching
  endpoints: (builder) => ({
    getAutopoolMembersAdmin: builder.query({
      query: () => "/api/v1/private/autopool_users",
      providesTags: ["adminEarning"], // automatic-data fetching
    }),
    addTreeAutoPoolAdmin: builder.mutation({
      // user login
      query: (body) => ({
        url: "/api/v1/private/autopool_tree_structure",
        method: "POST",
        body,
      }),
      invalidatesTags: ["adminEarning"], // automatic-data fetching
    }),
    getAutoPoolRegularIncomeHistoryByAdmin: builder.query({
      query: (query) =>
        `/api/v1/private/get_auto_pool_regular_income_by_admin?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query?.csv}`,
      providesTags: ["adminEarning"], // automatic-data fetching
    }),
    getAutoPoolRebirthIncomeHistoryByAdmin: builder.query({
      query: (query) =>
        `/api/v1/private/get_auto_pool_rebirth_ids_income_by_admin?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query?.csv}`,
      providesTags: ["adminEarning"], // automatic-data fetching
    }),

    getVipAutoPoolRegularIncomeHistoryByAdmin: builder.query({
      query: (query) =>
        `/api/v1/private/get_vip_auto_pool_regular_income_by_admin?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&vipType=${query.vipType}&csv=${query?.csv}`,
      providesTags: ["adminEarning"], // automatic-data fetching
    }),
    getVipAutoPoolRebirthIncomeHistoryByAdmin: builder.query({
      query: (query) =>
        `/api/v1/private/get_vip_auto_pool_rebirth_ids_income_by_admin?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&vipType=${query.vipType}&csv=${query?.csv}`,
      providesTags: ["adminEarning"], // automatic-data fetching
    }),

    getAutoPoolRegularIncomeHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/get_auto_pool_regular_income?page=${query?.page}&limit=${query?.limit}&slotId=${query?.slotId}`,
      providesTags: ["user"], // automatic-data fetching
    }),
    getAutoPoolRebirthIdsIncomeHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/get_auto_pool_rebirth_ids_income?page=${query?.page}&limit=${query?.limit}&slotId=${query?.slotId}&searchById=${query?.searchById}`,
      providesTags: ["user"], // automatic-data fetching
    }),
    getVipAutoPoolRegularIncomeHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/get_vip_auto_pool_regular_income?page=${query?.page}&limit=${query?.limit}&slotId=${query?.slotId}&vipType=${query?.vipType}`,
      providesTags: ["user"], // automatic-data fetching
    }),

    getUserActivationIncome: builder.query({
      // query: (user_id) => `/secure/api/user/get_investment/${user_id}`,
      query: () => `/secure/api/user_activation_income`,
      providesTags: ["user"], // automatic-data fetching
    }),

    //un use service
    getAutopoolRegularIncomeByAdmin: builder.query({
      query: (query) =>
        `/api/v1/private/get_auto_pool_regular_income_by_admin?page=${query?.page}&limit=${query?.limit}&slotId=${query?.slotId}&vipType=${query?.vipType}`,
      providesTags: ["user"], // automatic-data fetching
    }),
    getAutopoolRebirthIdsIncomeByAdmin: builder.query({
      query: (query) =>
        `/api/v1/private/get_auto_pool_rebirth_ids_income_by_admin?page=${query?.page}&limit=${query?.limit}&slotId=${query?.slotId}&vipType=${query?.vipType}`,
      providesTags: ["user"], // automatic-data fetching
    }),
    getAutopoolUserByAdmin: builder.query({
      query: (query) =>
        `/api/v1/private/get_auto_pool_users_by_admin?page=${query?.page}&limit=${query?.limit}&slotId=${query?.slotId}&vipType=${query?.vipType}`,
      providesTags: ["user"], // automatic-data fetching
    }),
    getAutopoolStructureByAdmin: builder.query({
      query: (query) =>
        `/api/v1/private/get_auto_pool_structure_by_admin?page=${query?.page}&limit=${query?.limit}&slotId=${query?.slotId}&vipType=${query?.vipType}`,
      providesTags: ["user"], // automatic-data fetching
    }),
    getTableViewAutopool: builder.query({
      query: (query) =>
        `/api/v1/private/get_table_view_auto_pool?page=${query?.page}&limit=${query?.limit}&slotId=${query?.slotId}&vipType=${query?.vipType}`,
      providesTags: ["user"], // automatic-data fetching
    }),
    enterAutoPoolUser: builder.mutation({
      // user login
      query: (body) => ({
        url: "/api/v1/secure/enter_to_auto_pool",
        method: "POST",
        body,
      }),
      invalidatesTags: ["auto-pool-user"], // automatic-data fetching
    }),
    getAutoPoolIncomeUser: builder.query({
      query: (query) =>
        `/api/v1/secure/get_auto_pool_regular_income?page=${query?.page}&limit=${query?.limit}&slotId=${query?.slotId}`,
      providesTags: ["auto-pool-user"], // automatic-data fetching
    }),
    getReBirthAutoPoolIncomeUser: builder.query({
      query: (query) =>
        `/api/v1/secure/get_auto_pool_rebirth_ids_income?page=${query?.page}&limit=${query?.limit}&slotId=${query?.slotId}`,
      providesTags: ["auto-pool-user"], // automatic-data fetching
    }),
    getHelpHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/get_help_history?page=${query?.page}&limit=${query?.limit}`,
      providesTags: ["help"], // automatic-data fetching
    }),
    getGiveHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/give_help_history?page=${query?.page}&limit=${query?.limit}`,
      providesTags: ["help"], // automatic-data fetching
    }),
    giveHelp: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/give_help",
        method: "POST",
        body,
      }),
      invalidatesTags: ["help"], // automatic-data fetching
    }),
  }),
});

export const {
  useGetVipAutoPoolRegularIncomeHistoryQuery,
  useGetVipAutoPoolRegularIncomeHistoryByAdminQuery,
  useGetVipAutoPoolRebirthIncomeHistoryByAdminQuery,
  useGetAutopoolMembersAdminQuery,
  useAddTreeAutoPoolAdminMutation,
  useGetAutoPoolRegularIncomeHistoryByAdminQuery,
  useGetAutoPoolRebirthIncomeHistoryByAdminQuery,
  useGetAutoPoolRegularIncomeHistoryQuery,
  useGetAutoPoolRebirthIdsIncomeHistoryQuery,
  useGetUserActivationIncomeQuery,

  useGetAutopoolRegularIncomeByAdminQuery,
  useGetAutopoolRebirthIdsIncomeByAdminQuery,
  useGetAutopoolUserByAdminQuery,
  useGetAutopoolStructureByAdminQuery,
  useGetTableViewAutopoolQuery,

  useEnterAutoPoolUserMutation,
  useGetAutoPoolIncomeUserQuery,
  useGetReBirthAutoPoolIncomeUserQuery,

  useGetHelpHistoryQuery,
  useGetGiveHistoryQuery,
  useGiveHelpMutation,
  useG,
} = earningApi;

import React from "react";
import tronImage from "../../../../assets/tron_live.png"
const About = ({refset}) => {
  return (
    <div ref={refset} className="about_container" id="home">
      <div className="container">
        <div className="about_inner">
          <div className="about_left">
            <h2>About Us</h2>
            <p>Welcome to Self Life Care, where we blend the power of community support with the innovation of crowdfunding to create a unique platform designed to support your financial goals and entrepreneurial dreams.  </p>

            <p>Our mission is to empower individuals and communities by providing the tools and resources necessary to raise funds, build networks, and achieve success together.</p>
          </div>
          <div className="about_right"></div>
        </div>
      </div>
    </div>
  );
};

export default About;

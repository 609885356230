import React from "react";

const Footer = () => {
  const base_url = window.location.origin;
  return (
    <footer style={{ padding: "0px 10px", textAlign: "center" }}>
      <p className="footer-text">
        Copyright © 2024-2040, Powered By{" "}
        <a href={base_url} target="_blank" rel="noreferrer">
          self life care
        </a>{" "}
      </p>
    </footer>
  );
};

export default Footer;

import React from "react";

const RegisterPopUpModel = ({
  openModal,
  setOpenModal,
  modalTitle,
  children,
  modalRef,
}) => {
  return (
    <>
      {openModal && (
        <>
          <dialog open ref={modalRef} className="register_popUp_modal">
            <div className="title">
              <h2>{modalTitle}</h2>
              <span onClick={() => setOpenModal(false)}>&times;</span>
            </div>
            {children}
          </dialog>
        </>
      )}
    </>
  );
};

export default RegisterPopUpModel;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: "cors",
    prepareHeaders: (headers) => {
      // console.log(getLocalStorage("self_life_care_token"))
      headers.set("authorization", getLocalStorage("self_life_care_token"));
      return headers;
    },
  }),
  tagTypes: ["User", "adminUser", "Validate", "autoTrade"], // automatic-data fetching
  endpoints: (builder) => ({
    getAutoPoolStructureView: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/get_auto_pool_structure_by_admin",
        method: "POST",
        body,
      }),
      invalidatesTags: ["adminUser"],
    }),
    getAutoPoolUsersIdByAdmin: builder.query({
      query: () => "/api/v1/private/get_auto_pool_users_by_admin",
      providesTags: ["User"], // automatic-data fetching
    }),

    getAutoPoolTable: builder.query({
      query: (query) =>
        `/api/v1/private/get_auto_pool_data_by_admin?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query?.csv}`,
      providesTags: ["User"], // automatic-data fetching
    }),

    getLoginUser: builder.query({
      query: () => "/api/v1/common/get_user",
      providesTags: ["User"], // automatic-data fetching
    }),
    getAutopoolOneStatus: builder.query({
      query: () => "/api/v1/secure/autopool_one_status",
      providesTags: ["User"], // automatic-data fetching
    }),
    // for auth user
    addUser: builder.mutation({
      // user register
      query: (body) => ({
        url: "/api/v1/public/register",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"], // automatic-data fetching
    }),
    addLogin: builder.mutation({
      // user login
      query: (body) => ({
        url: "/api/v1/public/login",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"], // automatic-data fetching
    }),
    addUserVerify: builder.mutation({
      query: (token) => ({
        url: `/api/v1/public/verify_user/${token}`,
        method: "PUT",
      }),
      invalidatesTags: ["User"], // automatic-data fetching
    }),
    addForgotPass: builder.mutation({
      query: (body) => ({
        url: "/api/v1/public/reset_password",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"], // automatic-data fetching
    }),
    addOtp: builder.mutation({
      query: (body) => ({
        url: "/api/v1/public/create_otp",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["User"], // automatic-data fetching
    }),
    addResetPass: builder.mutation({
      query: ({ token, password }) => ({
        url: `/api/v1/public/reset_password/${token}`,
        method: "POST",
        body: { password: password },
      }),
      invalidatesTags: ["User"], // automatic-data fetching
    }),
    getValidateSponsorName: builder.query({
      query: (user_id) => `/api/v1/public/get_sponsor/${user_id}`,
      providesTags: ["Validate"], // automatic-data fetching
    }),
    getValidateSponsorId: builder.query({
      query: (sponsor_id) => `/api/v1/public/get_sponsor/${sponsor_id}`,
      method: "GET",
      providesTags: ["Validate"], // automatic-data fetching
    }),
    getEligibility: builder.query({
      query: (sponsor_id) => `/api/v1/public/get_eligibility/${sponsor_id}`,
      method: "GET",
      providesTags: ["Validate"], // automatic-data fetching
    }),
    getHelpAndUpgradeEligibility: builder.query({
      query: (sponsor_id) =>
        `/api/v1/public/get_help_upgrade_eligibility/${sponsor_id}`,
      method: "GET",
      providesTags: ["Validate"], // automatic-data fetching
    }),
    getValidateEmail: builder.query({
      query: (email) => `/api/v1/public/check_email/${email}`,
      providesTags: ["Validate"], // automatic-data fetching
    }),
    getCheckCallingNumber: builder.query({
      query: (callingNumber) =>
        `/api/v1/public/check_calling_number/${callingNumber}`,
      providesTags: ["Validate"], // automatic-data fetching
    }),
    getValidateMobile: builder.query({
      query: (mobile) => `/api/v1/public/check_mobile/${mobile}`,
      providesTags: ["Validate"], // automatic-data fetching
    }),
    // get all team member list
    levelTeamList: builder.query({
      // query: (user_id) => `/secure/api/level_team/${user_id}`,
      query: (query) =>
        `/api/v1/secure/get_level_team?page=${query?.page}&limit=${query?.limit}&level=${query.level}`,
      providesTags: ["User"], // automatic-data fetching
    }),
    // get all team member list
    myTeamList: builder.query({
      // query: (user_id) => `/secure/api/level_team/${user_id}`,
      query: (query) =>
        `/api/v1/secure/get_my_team?page=${query?.page}&limit=${query?.limit}&level=${query.level}`,
      providesTags: ["User"], // automatic-data fetching
    }),
    getDirectTeam: builder.query({
      // query: (user_id) => `/secure/api/level_team/${user_id}`,
      query: (query) =>
        `/api/v1/secure/get_direct_team?page=${query?.page}&limit=${query?.limit}`,
      providesTags: ["User"], // automatic-data fetching
    }),
    getTeamBusiness: builder.query({
      // query: (user_id) => `/secure/api/level_team/${user_id}`,
      query: () => `/api/v1/secure/get_level_business`,
      providesTags: ["User"], // automatic-data fetching
    }),
    getMonthlyDirectMember: builder.query({
      query: () => `/secure/api/monthly_direct_member`,
      providesTags: ["User"], // automatic-data fetching
    }),
    getDashboardStats: builder.query({
      // SAST API
      query: () => `/api/v1/secure/get_dashboardStats_by_user`,
      providesTags: ["User"], // automatic-data fetching
    }),

    // for crud
    editUser: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/user/update_user_info",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    editPassword: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/user/change_password",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    editEmail: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/user/update_email",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    editTrxPassword: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/user/change_trx_password",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    editBankInfo: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/user/update_bank_info",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    editImage: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/user/upload_profile_pic",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    // admin
    getAllUser: builder.query({
      query: () => "/api/v1/private/user_analytics",
      providesTags: ["adminUser"], // automatic-data fetching
    }),
    allUserList: builder.query({
      query: (query) =>
        `/api/v1/private/user/get_all_users?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminUser"], // automatic-data fetching
    }),
    editUserList: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/user/edit_users",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["adminUser"],
    }),
    deleteUserList: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/user/delete_user",
        method: "PUT",
        body: { userId: body.userId },
      }),
      invalidatesTags: ["adminUser"],
    }),
    editUserStatus: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/user/change_user_status",
        method: "PUT",
        body: { user_id: body.userId },
      }),
      invalidatesTags: ["adminUser"],
    }),
    editAdminEmail: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/update_email",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["adminUser"],
    }),
    editAdminPassword: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/change_password",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["adminUser"],
    }),

    activeUserList: builder.query({
      query: (query) =>
        `/api/v1/private/user/get_active_users?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminUser"], // automatic-data fetching
    }),

    blockUserList: builder.query({
      query: (query) =>
        `/api/v1/private/user/get_blocked_users?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["adminUser"], // automatic-data fetching
    }),
    addAutoPoolController: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/change_autopool_status",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["adminUser"],
    }),
    getAutopoolStatus: builder.query({
      query: () => "/api/v1/private/get_autopool_setting",
      providesTags: ["adminUser"], // automatic-data fetching
    }),
    getMemberBusinessHistory: builder.query({
      query: ({
        level,
        userId,
        limit,
        page,
        startDate,
        endDate,
        searchById,
        csv,
      }) =>
        `/api/v1/private/user/get_team_stats_details?level=${level}&userId=${userId}&page=${page}&limit=${limit}&searchById=${searchById}&startDate=${startDate}&endDate=${endDate}&csv=${csv}`,
      providesTags: ["adminUser"], // automatic-data fetching
    }),
    addTeamStatistics: builder.mutation({
      query: (body) => ({
        url: "/api/v1/private/user/get_team_stats",
        method: "POST",
        body,
      }),
      invalidatesTags: ["adminUser"],
    }),
    // auto trade

    ///////////////////////// NEW ENDPOINT ////////////////////
    getAllUserStatistics: builder.query({
      query: () => "/api/v1/private/get_admin_dashboard_stats",
      providesTags: ["adminUser"], // automatic-data fetching
    }),
    getAllAreaData: builder.query({
      query: () => "/api/v1/private/get-area-data",
      providesTags: ["adminUser"], // automatic-data fetching
    }),

    getAllUserPIN: builder.query({
      query: (query) =>
        `/api/v1/private/user/get_all_pin?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query?.startDate}&endDate=${query?.endDate}&csv=${query?.csv}`,
      providesTags: ["adminUser"], // automatic-data fetching
    }),
    addPIN: builder.mutation({
      query: (body) => ({
        url: "/api/v1/secure/user/add_pin",
        method: "POST",
        body,
      }),
      invalidatesTags: ["adminUser"],
    }),

    getAllImage: builder.query({
      query: () => "/api/v1/public/get_all_image",
    }),
    getAllVedio: builder.query({
      query: () => "/api/v1/public/get_all_vedio",
    }),

    createContactForm: builder.mutation({
      query: (body) => ({
        url: "/api/v1/public/createContactForm",
        method: "POST",
        body,
      }),
    }),
    sendOTP: builder.mutation({
      query: (body) => ({
        url: "/api/v1/public/forgot_password",
        method: "POST",
        body,
      }),
    }),
    getPinHistory: builder.query({
      query: (query) =>
        `/api/v1/secure/user/get_pin_history?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query?.startDate}&endDate=${query?.endDate}&csv=${query?.csv}`,
      providesTags: ["User"], // automatic-data fetching
    }),
    getAutoPoolMemberHistory: builder.query({
      query: (query) =>
        `/api/v1/private/user/get_auto_pool_member?page=${query?.page}&limit=${query?.limit}&searchById=${query.searchById}&startDate=${query?.startDate}&endDate=${query?.endDate}&csv=${query?.csv}`,
      providesTags: ["User"], // automatic-data fetching
    }),
    checkPinStatus: builder.query({
      query: (pin) => `/api/v1/public/is_pin_valid/${pin}`,
      providesTags: ["User"], // automatic-data fetching
    }),
  }),
});

export const {
  useGetAutoPoolTableQuery,

  useGetAutoPoolStructureViewMutation,

  useGetAutoPoolUsersIdByAdminQuery,

  useGetLoginUserQuery,
  useGetAutopoolOneStatusQuery,
  useGetValidateSponsorNameQuery,
  useGetValidateSponsorIdQuery,
  useGetValidateEmailQuery,
  useGetValidateMobileQuery,
  useAddUserMutation,
  useAddLoginMutation,
  useAddForgotPassMutation,
  useAddResetPassMutation,
  useEditUserMutation,
  useEditPasswordMutation,
  useEditTrxPasswordMutation,
  useEditBankInfoMutation,
  useEditEmailMutation,
  useAddOtpMutation,
  useLevelTeamListQuery, // SAST API
  useGetDirectTeamQuery, // SAST API
  useEditImageMutation,
  useGetAllUserQuery,
  useAllUserListQuery,
  useActiveUserListQuery,
  useGetMonthlyDirectMemberQuery,
  useBlockUserListQuery,
  useEditUserListMutation,
  useEditUserStatusMutation,
  useDeleteUserListMutation,
  useEditAdminEmailMutation, // SAST API
  useEditAdminPasswordMutation, // SAST API
  useAddAutoPoolControllerMutation,
  useGetAutopoolStatusQuery,
  useAddTeamStatisticsMutation,
  /////////////////// NEW ENDPOINT ////////////////////////
  useGetAllUserStatisticsQuery,
  useGetDashboardStatsQuery, // SAST API
  useAddUserVerifyMutation,
  useGetMemberBusinessHistoryQuery,
  useGetTeamBusinessQuery,
  useGetAllUserPINQuery,

  useAddPINMutation,
  useGetAllImageQuery,
  useGetAllVedioQuery,

  useCreateContactFormMutation,

  useGetAllAreaDataQuery,
  useSendOTPMutation,
  useGetPinHistoryQuery,
  useGetAutoPoolMemberHistoryQuery,
  useCheckPinStatusQuery,
  useGetCheckCallingNumberQuery,
  useMyTeamListQuery,
  useGetEligibilityQuery,
  useGetHelpAndUpgradeEligibilityQuery,
} = userApi;
